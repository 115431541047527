body {
    margin: 0;
    padding: 0;
    font-family: Poppins;
}


.mainContainer{
    height:600px;
    width: 100%;
    background-image: url("../../assets/img/contact.jpg");
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    box-shadow:inset 0 0 0 2000px rgba(0, 0, 0, 0.521);
}

.logoContainer {
    margin-right: -10%;
    margin-bottom: 10%;
    z-index: 0;
}

.headingContainer {
    padding-right: 15%;
    z-index: 5;
}



.logo1 {
    width: 70%;
    opacity: .5;
}

.heading {
width: 312px;
font-style: normal;
font-weight: 600;
font-size: 110px;
line-height: 105px;
/* identical to box height */
color: #FFFFFF;
}

.subHeading {
    width: 487px;
    height: 53px;
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 52px;
    display: flex;
    align-items: center;
    color: #EA8075;
}

.separator {
    width: 100%;
    border: var(--burntSienna) 1px solid;
    margin-bottom: 5%;
}

.ctaHeroBtn {
    background-color: var(--burntSiennaLight);
    display: flex;
    justify-content: center !important;
    width: 50%;
    text-decoration: none;
    color: white !important;
    font-size: 20px;
    margin-top: 2%;
    padding: 2%;
    border: none;
    border-radius: var(--borderRadius);
    box-shadow: 3px 3px black;
    transition: all .2s ease-in-out;  
    font-weight: 500;
    text-decoration: none;
}

.ctaHeroBtn:hover {
    cursor: pointer;
    transform: scale(1.1); 
}

@media (max-width: 480px) {
    .mainContainer{
        height:600px;
        width: 100%;
        background-image: url("../../assets/img/contact.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        box-shadow:inset 0 0 0 2000px rgba(0, 0, 0, 0.521);
    }
    .logo1 {
        display: none;
    }
    .heading {
        font-size: 50px;
    }
    .subHeading {
        font-size: 25px;
    }
    .logoContainer {
        margin-right: 0;
        margin-bottom: 0;
        z-index: 0;
    }
    
    .headingContainer {
        padding-right: 0;
        z-index: 5;
    }
}