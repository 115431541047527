* {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    text-decoration: none;
    list-style: none;
}

.nav ul  {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 5%;
    background-color:#E9695A ;
    height: 100px;
}



.mobileNavigation {
    display: none;
}

@media (max-width: 900px) {
    .navigation{
        display: none;
    }

    .mobileNavigation{
        display: flex;
        align-items: center;
    }

    .mobileNavigation .hamburgerMenu {
        position: absolute;
        right: 5%;
        cursor: pointer;
    }
}

