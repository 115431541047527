.ctaBtnContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2%;
}

.ctaBtn {
    background-color: white;
    
    width: 50%;
    text-decoration: none;
    color: black;
    font-size: 20px;
    margin-top: 2%;
    padding: 2%;
    border: none;
    border-radius: var(--borderRadius);
    box-shadow: 3px 3px black;
    transition: all .2s ease-in-out;  
}

.ctaBtn:hover {
    cursor: pointer;
    transform: scale(1.1); 
}