@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@500&family=Poppins:ital,wght@0,400;1,100;1,400;1,600&display=swap");

* {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    text-decoration: none;
    list-style: none;
    
}

:root {
    /* colours */
    --burntSiennaLight: #e9695a;
    --naturalGrey: #8e8d8a;
    --satinLinen: #eae7dc;
    --akaroa: #d8c3a5;
    --burntSienna: #e85a4f;
    --dullWhite: #f4f4f4;
    --black: #000000;
    /* Measurements */
    --borderRadius: 8px;
    /*Button hover growth */
    --transition: all .2s ease-in-out; 
    --hover: transform: scale(1.1); 
}

