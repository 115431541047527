.containerAbout {
    padding: 3% 5%;
    margin: 0;
    height: 800px;
    display: flex ;
    top: 0;
    justify-content: center;
    align-content: center;
    background-color: #EEEEEE ;
}

.laptopImg {
    height: 500px;
}

.bodyText {
    padding-right: 5%;
}

.rightSide {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.leftSide {
    width: 40%;
    
}
.aboutContainer {
    padding: 3%;
    border-radius: 10px;
    /*box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);*/
    position: relative;
}


.shape2 {
    position:absolute;
    left: 500px;
}




