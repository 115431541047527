body {
    --main-pink-color: #E9695A;
}

.featuresSection {
    padding: 3% 5%;
    background-color: var(--main-pink-color);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
}


.box {
    background-color: white !important;
    border-radius: 10px;
    margin: 2%;
    margin-bottom: 5% !important;
    padding: 10%;
    margin: 0 1%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
}



.container {
    padding: 2px 16px;
    margin-bottom: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center !important;
}

.images {
    height: 400px;
}

.middleFeature {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
}

