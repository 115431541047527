a {
    color: white;
}

.section {
    color: white;
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url("../../assets/img/contact.jpg");
    background-position: center;
    background-size: cover;
    box-shadow:inset 0 0 0 2000px rgba(0, 0, 0, 0.521);

}

.ctaHeading {
    text-align: center;
    font-size: 50px;
}

.ctaSecBtn {
    background-color: var(--burntSiennaLight);
    display: flex;
    justify-content: center !important;
    width: 10%;
    text-decoration: none;
    color: black !important;
    font-size: 20px;
    margin-top: 2%;
    padding: 10px;
    border: none;
    border-radius: var(--borderRadius);
    box-shadow: 3px 3px black;
    transition: all .2s ease-in-out;  
    font-weight: 500;
    text-decoration: none;
}

.ctaSecBtn:hover {
    cursor: pointer;
    transform: scale(1.1); 
}