@media (prefers-reduced-motion: no-preference) {
    .polygon {
      animation: Polygon-spin infinite 50s linear;
    }
  }

  @keyframes Polygon-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }