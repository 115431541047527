.PricingSection {
    height: 600px;
    padding: 2%;
    display: flex;
    justify-content: center;

    flex-direction: row;
    align-items: center;
}

.PricingContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    height: 300px;
    padding: 5%;
    margin: 2% 3%;
    border-radius: 10px;
    background-color: #E9695A;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}
.PricingContainerFree {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    height: 400px !important;
    padding: 5%;
    margin: 2% 3%;
    border-radius: 10px;
    background-color: #E9695A;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}



.icon {
    display: flex;
    width: 20%;
    justify-content: center;
    align-items: center;
}